import {
	Box,
	Stack,
	Step,
	StepButton,
	Stepper,
	Theme,
	Typography,
	useMediaQuery,
} from "@mui/material";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useEventFromOrder from "services/Event/useEvent";
import { useCheckoutPageStateMutation } from "services/Order/mutations";
import useOrder from "services/Order/useOrder";
import useParticipant from "services/Team/useParticipant";
import { wmPalette } from "theme/WSGTheme";
import { MainCheckoutLayoutParams, PageId } from "types/common/pages";
import {
	AhotuMobileStepper,
	MobileStepperActionButton,
	stepperSx,
	stepperTextSx,
} from "./PageStepper.styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import AhotuTheme from "theme/AhotuTheme";
import { useTranslation } from "react-i18next";
import { UserJourneyMapStep } from "hooks/useUserJourneyMap";
import useDomain from "hooks/useDomain";
import { customFlatMap } from "services/common/utils/arrayUtils";

interface Step {
	key: string;
	name: string;
	pageId: number;
}

interface PageStepperProps {
	steps: UserJourneyMapStep[];
	hidden?: boolean;
}

const PageStepper = ({ steps, hidden }: PageStepperProps) => {
	const { orderId, participantToken } = useParams<MainCheckoutLayoutParams>();
	const order = useOrder(orderId);
	const event = useEventFromOrder(order.data?.eventId);
	const { isClaimSpotRoute, isChangeTicketRoute } = useCheckoutRouteMatch();
	const { data: participant } = useParticipant(orderId, participantToken);
	const navigate = useNavigate();
	const { t } = useTranslation("common");
	const updateCheckoutPageState = useCheckoutPageStateMutation();
	const domain = useDomain();
	const isTabletOrLarger = useMediaQuery((theme: Theme) =>
		theme.breakpoints.up("sm")
	);
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm")
	);

	const [activeStep, setActiveStep] = useState(0);

	const orderHasAddOns = useMemo(() => {
		const allExcludedTicketList: Array<string> = [];

		if (order.data && event.data) {
			if (order.data?.teamTicket) {
				return false;
			}

			const { extras } = event.data;
			if (extras) {
				const activeExtras = extras.filter((e) => e.active);
				const excludedTicketsFromExtras = customFlatMap(activeExtras, (e) => {
					if (e.excludedTickets && e.excludedTickets.length > 0) {
						return e.excludedTickets;
					}
					return [];
				});
				allExcludedTicketList.push(...excludedTicketsFromExtras);

				const addOnsContainTicketsFromOrder = order.data.participants.some(
					(p) => {
						const ticket = p.orderLines.find((ol) => ol.productType === "race");

						// If the count of a specific ticket ID is less than the number of active extras, then the ticket is not excluded
						return (
							allExcludedTicketList.filter((e) => e === ticket?.productId)
								.length < activeExtras.length
						);
					}
				);

				return addOnsContainTicketsFromOrder;
			}
		}

		return false;
	}, [event, order]);

	const filteredSteps = useMemo(
		() =>
			steps.filter((s) => {
				if (
					(s.key === "team-info" && !order.data?.teamTicket) ||
					(s.key === "addons" && !orderHasAddOns) ||
					(s.key === "itab" && !event.data?.hasITabEnabled)
				) {
					return null;
				}

				if (s.excludeOrigin === domain.data?.origin) {
					return null;
				}
				return s;
			}),
		[steps]
	);

	const isActive = (pageId: number, orderStateSequence: number) => {
		if (isClaimSpotRoute) {
			if (participant?.completed) {
				return pageId === PageId.Confirmation;
			}
			return pageId === PageId.PersonalDetails;
		}
		return pageId === orderStateSequence;
	};

	const handleStepClick = async (pageId: PageId) => {
		if (pageId === PageId.TicketSelect || pageId === undefined) {
			return history.back();
		}

		const newStepIsAfterActiveStep =
			filteredSteps.findIndex((s) => s.pageId === pageId) > activeStep;

		if (order.data?.isCompleted || newStepIsAfterActiveStep) {
			return;
		}

		if (orderId) {
			const orderAfterStateChange = await updateCheckoutPageState.trigger({
				orderId,
				state: pageId,
			});

			if (orderAfterStateChange) {
				order.mutate(orderAfterStateChange, { revalidate: false });
			}
		}
	};

	useEffect(() => {
		document.title = event.data?.title
			? `${event.data.title} | Checkout`
			: `Checkout | ${filteredSteps[activeStep]?.name}`;
	}, [event.data?.title, activeStep, filteredSteps]);

	useEffect(() => {
		const setStepper = (orderStateSequence: number) => {
			const page = filteredSteps.find((s) => s.pageId === orderStateSequence);
			if (page) {
				setActiveStep(filteredSteps.indexOf(page));
				if (!location.pathname.includes(`/${page.key}`)) {
					navigate(`${page.key}`);
				}
			}
		};

		if (order.data?.orderStateSequence !== undefined && isClaimSpotRoute) {
			setStepper(
				participant?.completed ? PageId.Confirmation : PageId.PersonalDetails
			);
		} else if (order.data?.orderStateSequence !== undefined && filteredSteps) {
			setStepper(order.data?.orderStateSequence);
		}
	}, [
		order.data?.orderStateSequence,
		navigate,
		filteredSteps,
		isClaimSpotRoute,
		participant?.completed,
	]);

	if (!order.data || !event.data || hidden) {
		return null;
	}

	if (isChangeTicketRoute !== null) {
		return <div></div>;
	}

	if (activeStep === filteredSteps.length - 1) {
		return <br />;
	}

	if (isMobile) {
		return (
			<Stack
				sx={{
					backgroundColor: (theme) =>
						theme.themeName === "ahotu"
							? AhotuTheme.AhotuColorPalette.grey[10]
							: wmPalette.grey[5],
					mb: "1rem",
				}}
			>
				<AhotuMobileStepper
					steps={filteredSteps.length - 1}
					activeStep={activeStep}
					position="static"
					backButton={
						<MobileStepperActionButton
							size="small"
							startIcon={<KeyboardArrowLeft />}
							disabled={
								updateCheckoutPageState.isMutating ||
								activeStep === filteredSteps.length - 1
							}
							variant="text"
							onClick={() =>
								handleStepClick(filteredSteps[activeStep - 1]?.pageId)
							}
						>
							{activeStep > 0
								? filteredSteps[activeStep - 1]?.name ?? t("common:back")
								: t("common:back")}
						</MobileStepperActionButton>
					}
					nextButton={<Box minWidth="64px" />}
					variant="text"
				/>
				<Typography
					variant="subtitle2"
					fontWeight="400"
					sx={{ textAlign: "center" }}
				>
					{filteredSteps[activeStep]?.name}
				</Typography>
			</Stack>
		);
	}

	return (
		<Stepper sx={stepperSx(steps.length)} nonLinear>
			{filteredSteps.map((s, index) => {
				if (!order.data || s.key === "confirmation") {
					return null;
				}

				return (
					<Step
						key={s.key}
						completed={index < activeStep}
						active={isActive(s.pageId, order.data.orderStateSequence)}
						sx={stepperTextSx}
					>
						<StepButton
							color={wmPalette.grey[10]}
							onClick={() => handleStepClick(s.pageId)}
						>
							{isTabletOrLarger && s.name}
						</StepButton>
					</Step>
				);
			})}
		</Stepper>
	);
};

export default PageStepper;
