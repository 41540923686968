import { PersonalDetails } from "pages/PersonalDetailsPage/PersonalDetails.schema";
import {
	Order,
	OrderLine,
	OrderParticipant,
} from "services/common/models/order";
import { DateParts, LocaleSettings, Nullable } from "types/common/global";
import { ITeamInfoForm } from "types/models/TeamInfo/teamInfoForm";
import { PageId } from "types/common/pages";
import { IAddOnParticipant } from "types/models/ParticipantAddOns/addOnParticipant";
import { PaymentIntent } from "@stripe/stripe-js";
import { PaypalIntent } from "types/models/PaypalRequestBody/PaypalIntent";
import { ITeamInfoMember } from "types/models/TeamInfo/teamInfoMember";
import { PostMutationActions } from "types/common/swr";
import { RequestWithOrderId } from "./common.types";
import { IITabOnParticipant } from "types/models/Participant/iTabOnParticipant";

export interface IOrderService {
	getOrder(
		orderId: string,
		providedCurrency?: string
	): Promise<Nullable<Order>>;
	submitPersonalDetails(
		orderId: string,
		data: ISubmitPersonalDetailsRequest
	): Promise<Nullable<Order>>;
	addParticipant(
		orderId: string,
		data: IAddParticipantData
	): Promise<Nullable<Order>>;
	changeParticipantTicket(
		orderId: string,
		participantId: string,
		productId: string
	): Promise<Nullable<Order>>;
	deleteParticipant(
		orderId: string,
		participantId: string
	): Promise<Nullable<Order>>;
	updateCheckoutPageState(
		orderId: string,
		state?: PageId
	): Promise<Nullable<Order>>;
	updateOrderAddOns(
		orderId: string,
		productId: string,
		addOnData: IAddOnParticipant[]
	): Promise<Nullable<Order>>;
	createPaymentIntention(
		orderId: string,
		skipValidation?: boolean
	): Promise<PaymentIntent | string>;
	updateRefundPreference(
		orderId: string,
		acceptsRefund: boolean
	): Promise<Nullable<Order>>;
	updateTermsAndConditionsState(
		orderId: string,
		acceptedConditions: TermsAndNewsletter
	): Promise<Nullable<TermsAndNewsletter>>;
	agreeToNewsLetter(
		orderId: string,
		acceptedConditions: TermsAndNewsletter
	): Promise<Nullable<AgreeToNewsletter>>;
	createPaypalPaymentIntent(orderId: string): Promise<Nullable<PaypalIntent>>;
	createFreeOrder(orderId: string): Promise<Nullable<string>>;
	agreeToWaiver(orderId: string, data: AgreeToWaiver): Promise<AgreeToWaiver>;
	agreeToAgeConsent(
		orderId: string,
		agreed: boolean
	): Promise<AgreeToAgeConsentResponse>;
	confirmPayPalOrder(
		orderId: string,
		paymentId: Nullable<string>,
		payerId?: Nullable<string>
	): Promise<boolean>;
}

export interface IGetOrderKey extends Partial<LocaleSettings> {
	orderId: string;
}

export type CustomQuestionFields = Record<string, string | number | boolean>;

export type IAddParticipantData = Array<{ productId: string }>;
export interface ParticipantPersonalDetails
	extends Omit<PersonalDetails, "dateOfBirth"> {
	orderLines: OrderLine[];
	id: string;
	fullName: string;
	birthDate: DateParts;
}

export interface ISubmitPersonalDetailsRequest extends RequestWithOrderId {
	participants: Array<ParticipantPersonalDetails>;
}

export interface IPageStateUpdateRequest extends RequestWithOrderId {
	state?: PageId;
	currency?: string;
}

export interface ICreatePaymentIntentionRequest extends RequestWithOrderId {
	skipValidation?: boolean;
	paymentIntentId: string | undefined;
}

export interface ISendToEmailRequest extends RequestWithOrderId {
	email?: string;
	shouldSendReceipt: boolean;
}

export interface ISendToEmailResponse {
	success: boolean;
	email: string;
}

export interface IAddTicketRequest extends RequestWithOrderId {
	data: Array<{ productId: string }>;
}

export interface SubmitTeamInformationData extends ITeamInfoForm {
	lng: string;
}

export type AddAdditionalTeamMemberRequest = RequestWithOrderId &
	ITeamInfoMember;

export type AddTeamMemberListRequest = {
	isSkipValidateTeamName?: boolean;
} & RequestWithOrderId &
	ITeamInfoForm;

export type DeleteParticipantRequest = RequestWithOrderId & {
	participantId: string;
};

export type CreatePayPalIntentRequest = RequestWithOrderId;

export type IUpdateRefundPreferenceRequest = RequestWithOrderId & {
	needsRefund: boolean;
};

export interface IAcceptInvitationRequest extends RequestWithOrderId {
	participantToken: string;
	participant: OrderParticipant;
}

export interface CreatePayPalIntentMutation {
	onIntentFail: (err?: string) => void;
}

export interface TermsAndNewsletter {
	terms: boolean;
	newsletter: boolean;
}

export interface AgreeToNewsletter {
	newsletter: boolean;
}

export interface AgreeToWaiver {
	waiver: boolean;
}

export interface AgreeToAgeConsentResponse {
	result: boolean;
	isValidResponse: boolean;
	errors: any[];
	validationErrors: any[];
	exceptions: any[];
}

export interface EmailValidationRequest {
	email: string;
}

export interface ApplyCouponCodeRequest {
	couponCode: string;
	orderId: string;
}

export interface PostSubmitPersonalDetailsActions extends PostMutationActions {
	onEmailMismatchError?: () => void;
}

export interface PostAcceptInvitationActions extends PostMutationActions {
	onEmailMismatchError?: () => void;
}

export const PersonalDetailsErrorMessageMap = {
	EmailMismatch:
		"Please use the email address, which is associated with your account.",
};

export const ContinueErrorMessageMap = {
	ValidationError: "ValidationError",
};

export type ITabPreferenceRequest = RequestWithOrderId & IITabOnParticipant;
export const AcceptInvitationErrorMessageMap = {
	EmailMismatch: "Please use the email address, which received the invitation.",
};
